import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {Grid} from '@mui/material'
import {useEffect, useState} from 'react'
import ArticlePreview from '../ArticlePreview/ArticlePreview'
import {getAllDataFromPath} from '../../../firebase'

const NewsPosts = () => {
  const [category1, setCategory1] = useState(null)
  const [category2, setCategory2] = useState(null)
  const [category3, setCategory3] = useState(null)
  const [category4, setCategory4] = useState(null)
  const style = {
    root: {
      background: '#f3f3f3',
      padding: '50px',
      display: 'flex',
      minHeight: '500px',
      borderRadius: '20px',
      marginBottom: '100px',
      flexDirection: 'column',
      marginTop: '100px',
    },
    h2: {
      fontSize: '38px',
      fontWeight: '800',
      textAlign: 'left',
      marginTop: '20px',
      width: '100%'
    },
    h3: {
      fontSize: '18px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'left',
      width: '100%'
    },
    button: {
      marginTop: '20px',
      padding: '6px 16px',
      borderRadius: '6px',
      minHeight: '31px',
      cursor: 'pointer',
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    buttonActive: {
      background: '#324b54',
      color: 'white',
      marginTop: '20px',
      padding: '6px 16px',
      borderRadius: '6px',
      minHeight: '31px',
      cursor: 'pointer',
      textTransform: 'uppercase',
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    articleImageActive: {
      width: '100%',
      marginTop: '20px',
      borderRadius: '20px'
    }
  }
  useEffect(() => {
    getAllDataFromPath('articlesBasic').then((res) => {
      if (res) {
        getAllDataFromPath('articles').then((resArt) => {
          res?.forEach((id) => {
              const idData = id?.data
              resArt?.forEach(elArt => {
                if (elArt.id === idData?.category1) setCategory1(elArt)
                if (elArt.id === idData?.category2) setCategory2(elArt)
                if (elArt.id === idData?.category3) setCategory3(elArt)
                if (elArt.id === idData?.category4) setCategory4(elArt)
              })
          })
        })
      }
    })
  }, [])
  return (
    <>
      {
        category1 && category2 && category3 && category4 && (
          <Box sx={style.root}>
            <Typography variant="h2" sx={style.h2}>
              Odkryj najpopularniejsze<br/> artykuły o fotografii
            </Typography>
            <Typography variant="h3" sx={style.h3}>
              Wiele z nich czeka na odkrycie
            </Typography>
            <Box sx={{ marginTop: '20px' }}>
              <Grid container>
                <Grid item md={5} xs={12}>
                  <ArticlePreview
                    noAuthor
                    limit={450}
                    sizeImageHeight={'350px'}
                    id={category1?.id}
                    title={category1?.data?.title}
                    descriptionSmall={category1?.data?.description}
                    image={category1?.data?.image?.length > 0 && category1?.data?.image[0]}
                  />
                </Grid>
                <Grid item md={7} xs={12}>
                  <ArticlePreview
                    viewLine
                    noAuthor
                    limit={300}
                    sizeImageHeight={'180px'}
                    id={category2?.id}
                    title={category2?.data?.title}
                    descriptionSmall={category2?.data?.description}
                    image={category2?.data?.image?.length > 0 && category2?.data?.image[0]}
                  />
                  <ArticlePreview
                    viewLine
                    noAuthor
                    limit={300}
                    sizeImageHeight={'180px'}
                    id={category3?.id}
                    title={category3?.data?.title}
                    descriptionSmall={category3?.data?.description}
                    image={category3?.data?.image?.length > 0 && category3?.data?.image[0]}
                  />
                  <ArticlePreview
                    viewLine
                    noAuthor
                    limit={300}
                    sizeImageHeight={'180px'}
                    id={category4?.id}
                    title={category4?.data?.title}
                    descriptionSmall={category4?.data?.description}
                    image={category4?.data?.image?.length > 0 && category4?.data?.image[0]}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        )
      }
    </>
  )
}

export default NewsPosts
