import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import UserHelper from '../../helpers/UserHelper/UserHelper'
import {useSnackbar} from 'notistack'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import {Button, useTheme} from '@mui/material'
import LocalStorageHelper from '../../helpers/LocalStorageHelper/LocalStorageHelper'
import Seo from '../../components/molecules/Seo/Seo'

const RememberPage = (props) => {
  const t = LocalStorageHelper.get('translation')
  const {enqueueSnackbar} = useSnackbar()
  const formData = {
    elements: [
      {
        name: 'email',
        type: 'email',
        label: t.globals.inputs.email.label,
        placeholder: 'Wpisz email',
        validationType: 'string'
      },
      {
        type: 'button',
        value: 'Zresestuj hasło'
      }
    ]
  }
  const handleRemember = (e) => {
    UserHelper.remember(e).then(res => {
      if (res) enqueueSnackbar('Wysłano wiadomość poprawnie, sprawdź email i zmień hasło.', {variant: 'success'})
      else enqueueSnackbar('Problem z wysłaniem wiadomości email.', {variant: 'error'})
    })
  }
  const style = {
    root: {
      background: 'white',
      padding: BreakpointsHelper.isMobile() ? '15px' : '50px',
      fontSize: BreakpointsHelper.isMobile() ? '24px' : '25px',
      fontWeight: 700,
      maxWidth: BreakpointsHelper.isMobile() ? 'none' : '320px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center'
    },
    desc: {
      maxWidth: '320px',
      fontSize: BreakpointsHelper.isMobile() ? '16px' : '18px',
      fontWeight: BreakpointsHelper.isMobile() ? '400' : '300',
      textTransform: 'none',
      textAlign: 'center'
    },
    icon: {
      marginRight: '5px'
    }
  }
  return (
    <>
      <Seo data={{ 'seo: title': 'Fotograf Gajdamowicz - Panel administracyjny' }}/>
      <div style={style.root}>
        {t.rememberPage.title}
        <div style={style.desc}>
          {t.rememberPage.description}
        </div>
        <FormGenerator data={formData} submit={(e) => handleRemember(e)} {...props}/>
        <Button
          sx={{marginBottom: '10px'}}
          fullWidth
          variant="outlined"
          onClick={() => props.location.history.push('/login')}
        >
          Logowanie
        </Button>
      </div>
    </>
  )
}

export default RememberPage
