import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {Avatar, Grid} from '@mui/material'
import profile from '../../../assets/images/profile.jpg'
import StringHelper from '../../../helpers/StringHelper/StringHelper'
import {useHistory} from 'react-router'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'

const ArticlePreview = ({title, descriptionSmall, image, date, viewLine = false, sx = null, noAuthor = false, id, sizeImageHeight = null, limit = 200}) => {
  const history = useHistory()
  const style = {
    root: {
      display: 'flex',
      flexDirection: 'row-reverse',
      cursor: 'pointer',
      justifyContent: viewLine ? 'space-between' : '',
    },
    h2: {
      fontSize: viewLine ? '20px' : '18px',
      marginRight: viewLine ? '0' : '40px',
      fontWeight: '800',
      textAlign: 'left',
      marginTop: '20px',
      width: viewLine ? '100%' : 'calc(100% - 40px)'
    },
    h3: {
      fontSize: '16px !important',
      marginRight: viewLine ? '0' : '40px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'left',
      width: viewLine ? '100%' : 'calc(100% - 40px)',
      marginBottom: '10px',
      textOverflow: 'hidden',
    },
    image: {
      width: viewLine ? '200px' : BreakpointsHelper.isMobile() ? '100%' : 'calc(100% - 40px)',
      minWidth: '200px',
      height: sizeImageHeight || (viewLine ? BreakpointsHelper.isMobile() ? '230px' : '200px' : noAuthor ? BreakpointsHelper.isMobile() ? '230px' : '200px' : 'auto'),
      marginLeft: viewLine ? '20px' : '0',
      objectFit: 'cover',
      marginTop: '20px',
      borderRadius: '20px',
      marginRight: viewLine ? '0' : BreakpointsHelper.isMobile() ? '0' : '40px'
    }
  }
  const handleOpenArticle = () => {
    history.push(`/showArticle/${id}`)
  }
  return (
    <Box sx={sx || (viewLine ? style.root : {cursor: 'pointer'})} onClick={() => handleOpenArticle()}>
      {
        image ? (
          <img
            src={image}
            alt="active image"
            style={style.image}
          />
        ) : (
          <Box sx={style.image}>
            <Box sx={{ background: 'grey', width: '100%', height: '100%', borderRadius: '20px' }}/>
          </Box>
        )
      }
      <Box>
        <Typography variant="h2" sx={style.h2}>
          {title}
        </Typography>
        <Typography className={'textsArticleSmall'} variant="h3" sx={style.h3} dangerouslySetInnerHTML={{ __html: StringHelper.add3Dots(descriptionSmall, limit) }}/>
        {
          !noAuthor && (
            <Grid container spacing={2}>
              <Grid item>
                <Avatar src={profile} alt="Profilowe Rafał Gajdamowicz"/>
              </Grid>
              <Grid item>
                <Typography variant="span" sx={{top: '10px', position: 'relative'}}>Rafał Gajdamowicz</Typography>
              </Grid>
            </Grid>
          )
        }
      </Box>
    </Box>
  )
}

export default ArticlePreview
