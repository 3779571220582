import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {Grid} from '@mui/material'
import Slider from '../../components/atoms/Slider/Slider'
import Category from '../../components/atoms/Category/Category'
import Button from '@mui/material/Button'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import NewsPosts from '../../components/molecules/NewsPosts/NewsPosts'
import Footer from '../../components/molecules/Footer/Footer'
import {useEffect, useState} from 'react'
import {getAllDataFromPath} from '../../firebase'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'
import Seo from '../../components/molecules/Seo/Seo'

const HomePage = (props) => {
  const [categories, setCategories] = useState(null)
  const [articles, setArticles] = useState(null)
  const [seoData, setSeoData] = useState(null)
  const style = {
    h2: {
      fontSize: '38px',
      fontWeight: '800',
      textAlign: 'left',
      marginTop: '10px',
      width: '100%'
    },
    h3: {
      fontSize: '18px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'left',
      width: '100%'
    },
    image: {
      width: '100%',
      display: 'flex',
      maxWidth: '320px',
      marginBottom: '30px'
    }
  }
  const getSeo = () => {
    getAllDataFromPath('seo').then((res) => {
      if (res) {
        setSeoData(res)
      }
    })
  }
  useEffect(() => {
    if (!articles) {
      getAllDataFromPath('articles').then((res) => {
        if (res) {
          setArticles(res)
        }
      })
    } else {
      getAllDataFromPath('categories').then((res) => {
        if (res) {
          const temp = []
          res.forEach(el => {
            const data = el?.data
            const id = el?.id
            let count = 0
            articles?.forEach(art => {
              if (art?.data?.category === id) {
                count++
              }
            })
            temp.push({
              name: data?.name,
              image: data?.image?.length > 0 ? data?.image[0] : '',
              link: `/showCategories/${id}`,
              articles: count,
            })
          })
          temp?.sort((el, next) => next?.articles - el?.articles)
          setCategories(temp)
        }
      })
    }
  }, [articles])
  useEffect(() => {
    getSeo()
  }, [])
  return (
    <MagicLoader variable={categories}>
      <Seo data={seoData?.length > 0 ? seoData.filter(el => el.id === 'HomePage')[0]?.data : {}}/>
      <SizeWindow>
        <Slider home/>
        <Box sx={{ margin: '50px auto', display: 'flex', justifyContent: 'space-between', width: '100%' }}>
          <Grid container spacing={2}>
            <Grid item md={6} xs={12}>
              <Typography variant="h2" sx={style.h2}>
                Najpopularniejsze kategorie
              </Typography>
              <Typography variant="h3" sx={style.h3}>
                Odkryj artykuły na temat fotografii
              </Typography>
            </Grid>
            <Grid item md={6} xs={12}>
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  onClick={() => window.open('/showCategories', '_self')}
                  variant="contained"
                >
                  Wszystkie dostępne kategorie
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
            padding: '50px 0',
            flexWrap: 'wrap'
          }}
        >
          {
            categories?.map((el, index) => {
              if (index < 10) {
                return (
                  <Category index={index} el={el} key={index}/>
                )
              }
              return ''
            })
          }
        </Box>
        <NewsPosts {...props} />
      </SizeWindow>
      <Footer />
    </MagicLoader>
  )
}

export default HomePage
