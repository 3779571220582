import StringHelper from '../StringHelper/StringHelper'

export default {
  addFieldSeo: (formData = { elements: []}, seoData = null) => {
    const elements = formData.elements
    const dataToAdd = [
      { name: 'seo: title', max: 56 },
      { name: 'seo: description' },
      { name: 'seo: page' },
      { name: 'seo: prase' },
      { name: 'seo: keywords' },
      { name: 'seo: category' },
      { name: 'seo: fbPageId' },
      { name: 'seo: ogTitle' },
      { name: 'seo: ogDescription' },
      { name: 'seo: ogImage' },
      { name: 'seo: twitterId' },
      { name: 'seo: twitterTitle' },
      { name: 'seo: twitterDescription' },
      { name: 'seo: twitterImage' },
    ]
    dataToAdd.forEach(el => {
      const dataValues = seoData?.length > 0 && seoData[0].data
      elements.push({ name: el.name, label: StringHelper.toCapitalize(el.name), value: dataValues[el?.name] || '', max: 56 })
    })
  }
}
