import React, { useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import createImagePlugin from '@draft-js-plugins/image'
import { uploadDataToUserStorage } from '../../../../firebase'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import LocalStorageHelper from '../../../../helpers/LocalStorageHelper/LocalStorageHelper'

const EditorText = ({ defaultValue, onChange, label, limit }) => {
  const [value, setValue] = useState('')
  const [length, setLength] = useState(0)
  const imagePlugin = createImagePlugin()

  const handleUpload = async (file) => {
    try {
      const user = LocalStorageHelper.get('user')
      const uploadResult = await uploadDataToUserStorage([file], null, '/files/')
      const imageURL = uploadResult[0]
      return imageURL
    } catch (error) {
      console.error('Error uploading image:', error)
      return null
    }
  }

  const handleInit = (evt, editor) => {
    setLength(editor?.getContent({ format: 'text' }).length)
  }

  const handleUpdate = (value, editor) => {
    const length = editor?.getContent({ format: 'text' }).length
    setValue(value)
    setLength(length)
    onChange(value)
  }

  const handleBeforeAddUndo = (evt) => {
    evt.preventDefault()
  }

  const handleImageUpload = async (e) => {
    const file = e.target.files[0]
    if (file) {
      const imageURL = await handleUpload(file)
      if (imageURL) {
        // Insert the image URL into the editor
        setValue(value + `<img src="${imageURL}" alt="Uploaded Image"/>`)
      }
    }
  }

  return (
    <Box sx={{ marginBottom: '20px' }}>
      <Typography sx={{ marginBottom: '10px', marginTop: '10px', position: 'relative', left: '2px' }}>{label}</Typography>
     <Box sx={{ marginBottom: '20px', width: '100%' }}>
       Dodaj zdjęcie do edytora: <input type="file" id="file-input" accept="image/*" onChange={handleImageUpload} />
     </Box>
      <Editor
        apiKey="vlbac076xsa600kfiyhynk4badrtexruxy6ip5qnsi7vnb0z"
        init={{
          plugins: 'searchreplace table autolink directionality visualblocks visualchars image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist code lists wordcount help charmap emoticons autosave  table autosave',
          toolbar: 'undo redo spellcheckdialog formatpainter | blocks fontfamily fontsize | bold italic underline forecolor backcolor | link image addcomment showcomments  | alignleft aligncenter alignright alignjustify lineheight | checklist bullist numlist indent outdent | removeformat code',
          images_reuse_filename: true,
          automatic_uploads: false
        }}
        value={value}
        initialValue={defaultValue}
        onInit={handleInit}
        onEditorChange={(e, editor) => handleUpdate(e, editor)}
        onBeforeAddUndo={handleBeforeAddUndo}
      />
    </Box>
  )
}

export default EditorText
