import ReactDOM from 'react-dom/client'
import App from './App'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import ToastProvider from './components/organisms/ToastProvider/ToastProvider'

const root = ReactDOM.createRoot(document.getElementById('root'))

const error = console.error
console.error = (...args) => {
  if (/defaultProps/.test(args[0])) return
  error(...args)
}

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#324b54',
      black: '#000000',
      white: '#ffffff'
    },
    secondary: {
      main: '#66bb6a',
      second: '#ab47bc',
      grey: '#B5B5B5',
      greyLight: '#EBEBEB',
      greyDark: '#767373'
    },
    toasts: {
      success: '#2e7d32',
      warning: '#ed6c02',
      error: '#d32f2f',
      info: '#0288d1'
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  typography: {
    useNextVariants: true
  },
  config: {
    maxToasts: 5,
    seo: {
      author: 'Your site, kontakt@your-site.pl',
      url: 'https://fotografgajdamowicz.pl',
      website: 'fotografgajdamowicz.pl',
      smallDescription: '\n' +
        'Jestem fotografem, a moja strona internetowa to wirtualne przestrzenie, gdzie obrazy stają się opowieściami. Moje ujęcia to nie tylko technika, lecz także wyraz artystycznej wrażliwości. Przez światło, kolor i kompozycję staram się przekazać emocje związane z każdą chwilą. Zachęcam do odkrywania mojego portfolio i zanurzenia się w fascynującym świecie mojej fotografii.',
      name: 'Rafał Gajdamowicz',
      email: 'studio@gajdamowicz.art',
      phone: '+48 723 421 062',
      company: 'Fotograf Gajdamowicz',
      address: 'ul. Zwycięstwa 51/1, Koszalin',
      postalCode: '',
      city: 'Koszalin',
      regin: 'Zachodniopomorskie',
      country: 'Polska',
      facebook: 'https://www.facebook.com/FotografKoszalinFotografGajdamowicz',
      youtube: 'https://www.youtube.com/@FotografGajdamowicz',
      instagram: 'https://www.instagram.com/artgajdamowicz',
      hours: {
        ponPt: '8:00 - 17:00',
        sob: '10:00 - 15:00',
        nd: 'Nieczynne'
      }
    }
  }
})

// eslint-disable-next-line jest/require-hook
root.render(
  <ToastProvider theme={theme}>
    <ThemeProvider theme={theme}>
      <App/>
    </ThemeProvider>
  </ToastProvider>
)
