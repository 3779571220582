import Footer from '../../components/molecules/Footer/Footer'
import {Grid} from '@mui/material'
import SizeWindow from '../../components/atoms/SizeWindow/SizeWindow'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import kontakt from '../../assets/images/kontakt.jpg'
import Seo from '../../components/molecules/Seo/Seo'
import {useEffect, useState} from 'react'
import {getAllDataFromPath} from '../../firebase'

const ContactPage = () => {
  const [seoData, setSeoData] = useState(null)
  const style = {
    banner: {
      height: '300px',
      padding: '100px',
      display: 'flex',
      alignItems: 'center',
      width: 'calc(100% - 200px)',
      justifyContent: 'center',
      background: `url(${kontakt})`,
      borderRadius: '50px',
      backgroundPosition: 'center center',
      backgroundSize: '100% auto',
      fitObject: 'cover',
      position: 'relative',
      marginBottom: '50px',
      marginTop: '100px',
    },
    blackBg: {
      position: 'absolute',
      zIndex: 1,
      width: '100%',
      height: '100%',
      borderRadius: '50px',
      background: 'rgba(0,0,0,.3)'
    },
    texts: {
      position: 'absolute',
      zIndex: 2,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    title: {
      color: 'white',
      fontWeight: 900,
      fontSize: '65px',
      lineHeight: 1.1
    },
    desc: {
      marginTop: '20px',
      color: 'white',
      fontWeight: 400,
      fontSize: '24px',
      lineHeight: 1.1
    }
  }
  const getSeo = () => {
    getAllDataFromPath('seo').then((res) => {
      if (res) {
        setSeoData(res)
      }
    })
  }
  useEffect(() => {
    getSeo()
  }, [])
  return (
    <>
      <Seo data={seoData?.length > 0 ? seoData.filter(el => el.id === 'ContactPage')[0]?.data : {}}/>
      <SizeWindow minHeight="300px">
        <Box sx={style.banner}>
          <Box sx={style.texts}>
            <Typography variant="h1" sx={style.title}>
              Kontakt
            </Typography>
          </Box>
          <Box sx={style.blackBg}/>
        </Box>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9337.743878719348!2d16.1803567!3d54.1900643!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47004bf39bd0aa45%3A0xd68f25c3557499cb!2sFotograf%20Koszalin%20-%20Fotograf%20Gajdamowicz%20-%20Zdj%C4%99cia%20do%20dokument%C3%B3w%20Koszalin!5e0!3m2!1sen!2spl!4v1703716253585!5m2!1sen!2spl"
          style={{ border: 0, height: '400px', width: '100%', marginBottom: '50px', borderRadius: '50px', filter: 'grayscale(1.0)' }}
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"></iframe>
        <Grid container spacing={4}>
          <Grid item md={4} xs={12}>
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
              <Typography sx={{fontSize: '24px', fontWeight: '600', marginBottom: '10px'}}>Przyjedź</Typography>
              <Typography>ul. Zwycięstwa 51/1, 75-020 Koszalin</Typography>
              <Typography>( naprzeciwko Katedry koło optyka )</Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
              <Typography sx={{fontSize: '24px', fontWeight: '600', marginBottom: '10px'}}>Napisz</Typography>
              <Typography>studio@gajdamowicz.art</Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
              <Typography sx={{fontSize: '24px', fontWeight: '600', marginBottom: '10px'}}>Zadzwoń</Typography>
              <Typography>+48 723 421 062</Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
              <Typography sx={{fontSize: '24px', fontWeight: '600', marginBottom: '10px'}}>Godziny otwarcia</Typography>
              <Typography>Pon - Pt: 8:00 - 17:00</Typography>
              <Typography>Sobota: 10:00 - 15:00</Typography>
              <Typography>Niedziela: Nieczynne</Typography>
            </Box>
          </Grid>
          <Grid item md={4} xs={12}>
            <Box sx={{display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
              <Typography sx={{fontSize: '24px', fontWeight: '600', marginBottom: '10px'}}>Dane szczegółowe</Typography>
              <Typography>NIP: 6722018219</Typography>
              <Typography>REGON: 386447489</Typography>
            </Box>
          </Grid>
        </Grid>
      </SizeWindow>
      <Footer/>
    </>
  )
}

export default ContactPage
