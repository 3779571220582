import Box from '@mui/material/Box'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import {useHistory} from 'react-router'
import imageDefault from '../../../assets/configFiles/favicon.png'

const Category = (props) => {
  const {el, index} = props
  const history = useHistory()
  const style = {
    root: {
      position: 'relative',
      background: 'white',
      display: 'flex',
      padding: '20px',
      borderRadius: '25px',
      alignItems: 'center',
      justifyContent: 'center',
      marginRight: '20px',
      flex: `1 1 ${BreakpointsHelper.checkSizeWindow('lg', 'down') ? 'calc(30% - 60px)' : 'calc(20% - 60px)'}`,
      marginBottom: '20px',
      flexDirection: 'column',
      cursor: 'pointer',
    },
    adds: {
      position: 'absolute',
      zIndex: 2,
      top: '15px',
      right: '25px',
      background: '#324b54',
      padding: '2px 8px',
      color: 'white',
      borderRadius: '6px',
    },
    img: {
      borderRadius: '50%',
      width: '90px',
      height: '90px',
      marginBottom: '20px'
    },
    title: {
      fontSize: '16px',
      fontWeight: '500'
    },
    articles: {
      fontSize: '14px',
      fontWeight: '400'
    }
  }
  const handleOpenCategoryPage = () => {
    history.push(el?.link)
  }
  return (
    <Box sx={style.root} onClick={() => handleOpenCategoryPage()}>
      {
        index === 0 && (
          <Box sx={style.adds}>
            #1
          </Box>
        )
      }
      {
        index === 1 && (
          <Box sx={style.adds}>
            #2
          </Box>
        )
      }
      {
        index === 2 && (
          <Box sx={style.adds}>
            #3
          </Box>
        )
      }
      <img src={el?.image || imageDefault} alt={el.name} style={style.img}/>
      <Box sx={style.title}>{el.name}</Box>
      <Box sx={style.articles}>Artykułów: {el.articles}</Box>
    </Box>
  )
}

export default Category
