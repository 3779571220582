import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import {Grid} from '@mui/material'
import Button from '@mui/material/Button'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'

const Competition = (props) => {
  const { name, region, dates, city, last } = props
  return (
    <Grid item md={12} xs={12}>
      <Box
        sx={{
          boxShadow: '2px 2px 10px 2px rgba(0,0,0,.1)',
          padding: '20px',
          marginBottom: '20px',
        }}
      >
        <Grid container spacing={4}>
          <Grid item md={last ? 7 : 12} xs={12}>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                justifyContent: 'center',
                height: '100%',
              }}
            >
              <Typography sx={{ fontSize: '18px', color: '#4a4a4a', fontWeight: '400' }}>
                {city}
              </Typography>
              <Typography sx={{ fontWeight: '600', fontSize: '22px' }}>
                {name}
              </Typography>
              <Typography sx={{ fontWeight: '400', fontSize: '20px' }}>
                {region} | {dates}
              </Typography>
            </Box>
          </Grid>
          {
            last && (
              <Grid item md={5} xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'row',
                    justifyContent: 'flex-end',
                    height: '100%',
                    flex: 1,
                  }}
                >
                  {
                    LocalStorageHelper.get('user')?.role === 'admin' && (
                      <Button
                        sx={{ marginRight: '20px' }}
                        variant="contained"
                      >
                        Dodaj zdjęcia
                      </Button>
                    )
                  }
                  <Button
                    variant="contained"
                  >
                    Zamów zdjęcia
                  </Button>
                </Box>
              </Grid>
            )
          }
        </Grid>
      </Box>
    </Grid>
  )
}

export default Competition
