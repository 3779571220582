import {useEffect, useState} from 'react'
import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import logo from '../../../assets/configFiles/logo.png'
import Box from '@mui/material/Box'
import MenuIcon from '@mui/icons-material/Menu'
import {Menu, MenuItem} from '@mui/material'
import Button from '@mui/material/Button'
import {getAllDataFromPath} from '../../../firebase'
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state'

const NavigationPreview = () => {
  const [open, setOpen] = useState(false)
  const [silos, setSilos] = useState(null)
  const [categories, setCategories] = useState(null)
  const [pagesVar, setPagesVar] = useState(null)
  const style = {
    nav: {
      position: 'fixed',
      top: 0,
      right: 0,
      width: '100%',
      height: '90px',
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      zIndex: 999,
      boxShadow: '2px 2px 20px 10px rgba(0, 0, 0, 0.02)'
    },
    logo: {
      maxWidth: BreakpointsHelper.isMobile() ? '200px' : '320px',
      cursor: 'pointer',
      marginLeft: BreakpointsHelper.isMobile() ? '30px' : '0',
    },
    menu: {
      width: 30,
      height: 30,
      color: '#324b54',
      cursor: 'pointer',
      marginRight: '30px',
    },
    logoOpen: {
      height: BreakpointsHelper.isMobile() ? '55px' : '100%',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '35px',
      position: 'relative',
      left: '-5px',
      bottom: '-3px'
    },
    buttons: {
      display: 'flex',
      alignItems: 'center'
    },
    burgerOpen: {
      height: '50px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      position: 'relative',
      right: '-5px',
      bottom: '-2px',
      marginBottom: '30px'
    },
    elementNav: {
      fontSize: '18px',
      marginLeft: BreakpointsHelper.isMobile() ? '0' : '40px',
      cursor: 'pointer',
      border: BreakpointsHelper.isMobile() && '1px solid black',
      padding: BreakpointsHelper.isMobile() && '20px',
      textAlign: BreakpointsHelper.isMobile() && 'center',
      marginBottom: BreakpointsHelper.isMobile() && '20px'
    },
    menuRight: {
      display: 'flex'
    }
  }
  const getArticlesFromId = async (id) => {
    const articles = []
    await getAllDataFromPath('articles').then((res) => {
      if (res) {
        res?.forEach(el => {
          if (el?.data?.category === id) {
            articles.push(el)
          }
        })
      }
    })
    return articles
  }
  const openLink = (link) => window.open(link, '_self')
  const generateMenu = (pagesVar) => {
    return (
      <header style={{display: 'flex'}}>
        <Box sx={{flexGrow: 1, display: 'flex', flexDirection: BreakpointsHelper.isMobile() ? 'column' : 'row', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
          {
            pagesVar?.map((page, index) => {
              if (page?.ul?.length > 0) {
                return (
                  <>
                    <PopupState variant="popover" popupId="demo-popup-menu">
                      {(popupState) => (
                        <>
                          <Button
                            sx={{ fontSize: BreakpointsHelper.isMobile() ? '12px' : '14px', marginLeft: '5px', justifyContent: 'flex-start' }}
                            variant="text"
                            {...bindTrigger(popupState)}
                          >
                            {page?.name || ''}
                          </Button>
                          <Menu sx={{ position: 'absolute', marginTop: '25px', width: '100%', left: '0' }} {...bindMenu(popupState)}>
                            {
                              page?.ul?.map((element, key) => {
                                return (
                                  <>
                                    <MenuItem
                                      key={key}
                                      sx={{ textTransform: 'uppercase', fontWeight: '600' }}
                                      onClick={() => openLink(element.link)}
                                    >
                                      {element.name}
                                    </MenuItem>
                                    {
                                      element?.ul?.length > 0 && element?.ul.map((el, elKey) => {
                                        return (
                                          <MenuItem
                                            key={elKey}
                                            sx={{textTransform: 'uppercase'}}
                                            onClick={() => openLink(el.link)}
                                          >
                                            {el.name}
                                          </MenuItem>
                                        )
                                      })
                                    }
                                  </>
                                )
                              })
                            }
                          </Menu>
                        </>
                      )}
                    </PopupState>
                  </>
                )
              }
              return (
                <Button
                  key={index}
                  onClick={() => openLink(page.link)}
                  variant="text"
                  className={window.location.pathname === page.link && 'active'}
                  sx={{
                    justifyContent: 'flex-start',
                    fontSize: BreakpointsHelper.checkSizeWindow('lg', 'down') ? '12px' : '14px',
                    marginLeft: '5px',
                  }}
                >
                  {page.name}
                </Button>
              )
            })
          }
        </Box>
      </header>
    )
  }
  useEffect(() => {
    getAllDataFromPath('silos').then((res) => {
      if (res) {
        setSilos(res)
      }
    })
    getAllDataFromPath('categories').then((res) => {
      if (res) {
        setCategories(res)
      }
    })
  }, [])
  useEffect(() => {
    if (silos && categories) {
      const pages = [
        {
          name: 'Home',
          link: '/'
        },
      ]
      silos?.forEach(el => {
        const data = el?.data
        if (data?.silos === 'menu') {
          const menu = {
            name: data?.name,
            link: '/',
            ul: []
          }
          categories?.forEach(elCat => {
            const dataCat = elCat?.data
            if (dataCat?.silos === data?.name) {
              menu.ul.push({
                name: dataCat?.name,
                link: `/showCategories/${elCat?.id}`,
                ul: [],
              })
            }
          })
          pages.push(menu)
        }
      })
      const offer = {
        name: 'Oferta',
        link: '/',
        ul: []
      }
      silos?.forEach(el => {
        const data = el?.data
        if (data?.silos === 'oferta') {
          offer.ul.push({
            name: data?.name,
            link: `/showCategories/${el?.id}`,
          })
        }
      })
      pages.push(offer)
      const blog = {
        name: 'Blog',
        link: '/',
        ul: [],
      }
      pages.push(blog)
      const gallery = {
        name: 'Galeria',
        link: '/gallery',
        ul: [],
      }
      pages.push(gallery)
      silos?.forEach(el => {
        const data = el?.data
        if (data?.silos === 'blog') {
          blog?.ul?.push({
            name: data?.name,
            link: `/showCategories/${el?.id}`,
          })
        }
      })
      pages.push(
        {
          name: 'Kontakt',
          link: '/contact'
        })
      setPagesVar(pages)
    }
  }, [silos, categories])
  useEffect(() => {
    if (pagesVar) {
      const tempPagesVar = pagesVar
      tempPagesVar.forEach((el) => {
        if (el.name !== 'Home' && el.name !== 'Galeria' && el.name !== 'Kontakt') {
          const ulEl = el.ul
          ulEl.forEach(elEl => {
            const id = elEl.link.split('/')[elEl.link.split('/')?.length - 1]
            getArticlesFromId(id).then((res) => {
              if (res) {
                res?.forEach(art => {
                  const title = art.data.title
                  elEl.ul.push({
                    name: title,
                    link: `/showArticle/${art?.id}`,
                  })
                })
              }
            })
          })
        }
      })
    }
  }, [pagesVar])
  return (
    <div style={style.nav} className="navigation">
      <Box
          sx={{
            maxWidth: '1440px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            width: '100%',
            margin: '0 auto',
        }}
      >
        <img src={logo} onClick={() => openLink('/home')} alt={'logo'} style={style.logo}/>
        <Box sx={style.menuRight}>
          {
            BreakpointsHelper.isMobile()
                ? (
                    <div style={style.burger} onClick={() => setOpen(!open)}>
                      <MenuIcon style={style.menu}/>
                    </div>
                )
                : silos?.length > 0 && generateMenu(pagesVar)
          }
          {
              open && (
                  <Box
                      sx={{
                        position: 'absolute',
                        zIndex: '999',
                        background: 'white',
                        top: '90px',
                        left: '0',
                        height: 'calc(100vh - 90px)',
                        width: 'calc(100% - 40px)',
                        display: 'flex',
                        flexDirection: 'column',
                        padding: '20px'
                      }}
                  >
                    {silos?.length > 0 && generateMenu(pagesVar)}
                  </Box>
              )
          }
        </Box>
      </Box>
    </div>
  )
}

export default NavigationPreview
