import Seo from '../../components/molecules/Seo/Seo'
import {Grid} from '@mui/material'
import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import FormGenerator from '../../components/organisms/FormGenerator/FormGenerator'
import {getAllDataFromPath, updateOrCreateDocument} from '../../firebase'
import {useSnackbar} from 'notistack'
import {useEffect, useState} from 'react'
import MagicLoader from '../../components/atoms/MagicLoader/MagicLoader'

const EditGalleryPage = () => {
    const idElement = window.location.pathname.split('/')[2]
    const {enqueueSnackbar} = useSnackbar()
    const style = {
        root: {
            width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
            padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
        },
        arrayNotExist: {
            fontSize: '16px',
            marginTop: '20px',
            color: '#324b54',
            background: '#ededed',
            padding: '20px',
            width: 'auto',
            borderRadius: '50px',
        }
    }
    const [formDataSilos, setFormDataSilos] = useState({})
    const handleSubmit = (e) => {
        updateOrCreateDocument('galleries', idElement, e).then((res) => {
            if (res) {
                enqueueSnackbar('Zapisano dane poprawnie.', {variant: 'success'})
                setTimeout(() => {
                    window.open(window.location.pathname, '_self')
                }, 1000)
            }
        })
    }
    const handleDelete = (e, el) => {
        const tempData = el
        const newArrayImages = []
        el?.data?.images?.forEach(element => {
            if (element !== e) {
                newArrayImages.push(element)
            }
        })
        tempData.data.images = newArrayImages
        updateOrCreateDocument('galleries', idElement, tempData.data).then((res) => {
            if (res) {
                enqueueSnackbar('Usunięto zdjęcie z galerii poprawnie.', {variant: 'success'})
                getData()
            }
        })
    }
    const getData = () => {
        getAllDataFromPath('galleries').then((res) => {
            if (res) {
                res?.forEach(el => {
                    if (el?.id === idElement) {
                        const formDataGalleries = {
                            elements: [
                                {
                                    name: 'categoryName',
                                    type: 'text',
                                    value: el?.data?.categoryName,
                                    label: 'Nazwa nowej kategorii',
                                },
                                {
                                    name: 'images',
                                    type: 'files',
                                    value: el?.data?.images,
                                    possibleDelete: true,
                                    onDelete: (e) => handleDelete(e, el),
                                    label: 'Zdjęcia w galerii',
                                },
                                {
                                    value: 'Zapisz kategorię galerii',
                                    type: 'button',
                                }
                            ],
                        }
                        setFormDataSilos(formDataGalleries)
                    }
                })
            }
        })
    }
    useEffect(() => {
        getData()
    }, [])
    return (
        <>
            <Seo data={{'seo: title': 'Fotograf Gajdamowicz - Panel administracyjny'}}/>
            <MagicLoader variable={formDataSilos?.elements?.length > 0}>
                <div style={style.root}>
                    <Grid container spacing={8}>
                        <Grid item md={6} xs={12}>
                            <FormGenerator data={formDataSilos} submit={(e) => handleSubmit(e)}/>
                        </Grid>
                    </Grid>
                </div>
            </MagicLoader>
        </>
    )
}

export default EditGalleryPage
