import Box from '@mui/material/Box'

const SizeWindow = (props) => {
  const { children, alignItems, justifyContent, minHeight } = props
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: alignItems && 'center',
        justifyContent: justifyContent || 'center',
        flexDirection: 'column',
        width: 'calc(100% - 60px)',
        minHeight: minHeight || 'calc(100vh - 90px)',
        position: 'relative',
        zIndex: 1,
        padding: '0 30px',
        maxWidth: '1440px',
        margin: '0 auto'
      }}
    >
      {children}
    </Box>
  )
}

export default SizeWindow
