import { useEffect, useState } from 'react'
import {CircularProgress} from '@mui/material'
import Box from '@mui/material/Box'

const MagicLoader = (props) => {
  const { children, callback, variable = true, time } = props
  const [isLoading, setIsLoading] = useState(true)
  const style = {
    root: {
      position: 'absolute',
      zIndex: '999',
      width: '100%',
      height: '100%',
      background: 'white',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
  }
  useEffect(() => {
    let timeLoad = null
    setIsLoading(true)
    if (!callback) {
      timeLoad = setTimeout(() => {
        if (variable) setIsLoading(false)
      }, time || 500)
    } else callback(setIsLoading)
    return () => clearTimeout(timeLoad)
  }, [variable])
  return (
    <>
      {
        isLoading
          ? (
            <Box className="MagicLoader" sx={style.root}>
              <CircularProgress/>
            </Box>
          )
          : children
      }
    </>
  )
}

export default MagicLoader
