import BreakpointsHelper from '../../../helpers/BreakpointsHelper/BreakpointsHelper'
import Box from '@mui/material/Box'
import {Grid, useTheme} from '@mui/material'
import logo from '../../../assets/configFiles/logo.png'
import Typography from '@mui/material/Typography'
import LocalStorageHelper from '../../../helpers/LocalStorageHelper/LocalStorageHelper'

const Footer = () => {
  const t = LocalStorageHelper.get('translation')
  const theme = useTheme()
  const { company, phone, email, address, facebook, youtube, instagram, hours, smallDescription } = theme.config.seo
  const style = {
    h2: {
      fontSize: '38px',
      fontWeight: '800',
      textAlign: 'left',
      marginTop: '10px',
      width: '100%'
    },
    h3: {
      fontSize: '18px',
      fontWeight: '400',
      marginTop: '10px',
      textAlign: 'left',
      width: '100%',
      marginBottom: '20px',
    },
    image: {
      width: '100%',
      display: 'flex',
      maxWidth: '320px',
      marginBottom: '30px'
    }
  }
  return (
    <Box
      sx={{
        display: 'inline-flex',
        alignItems: BreakpointsHelper.isMobile() ? 'flex-start' : 'center',
        justifyContent: 'center',
        flexDirection: BreakpointsHelper.isMobile() && 'column',
        padding: '0 50px',
        width: 'calc(100% - 100px)',
        borderTop: '1px solid rgb(229,231,235)',
        marginTop: '100px',
      }}
    >
      <Box sx={{maxWidth: '1440px', display: 'flex', alignItems: 'flex-start', padding: '50px 0 0 0'}}>
        <Grid container spacing={8}>
          <Grid item md={4} xs={12}>
            <img src={logo} alt="logo" style={style.image}/>
            <Typography variant="p" sx={{width: '100%'}}>
              {smallDescription}
            </Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography className="bold" variant="h2" sx={style.h3}>
              Skontaktuj się ze mną
            </Typography>
            <Typography sx={{ marginTop: '10px' }}><b>Telefon:</b> {phone}</Typography>
            <Typography><b>Email:</b> {email}</Typography>
            <Typography><b>Adres:</b> {address}</Typography>
            <Typography sx={{ marginTop: '10px' }}><b>Godziny otwarcia:</b></Typography>
            <Typography>Pon - Pt: {hours.ponPt}</Typography>
            <Typography>Sobota: {hours.sob}</Typography>
            <Typography>Niedziela: {hours.nd}</Typography>
          </Grid>
          <Grid item md={4} xs={12}>
            <Typography className="bold" variant="h2" sx={style.h3}>
              Zasoby dodatkowe
            </Typography>
            <Typography><a href={'/regulation'} target="_self">Regulamin serwisu</a></Typography>
            <Typography><a href={'/policy'} target="_self">Polityka prywatności</a></Typography>
            {
              facebook && (
                <Typography><a href={facebook} target="_blank" rel="noreferrer">Facebook</a></Typography>
              )
            }
            {
              instagram && (
                <Typography><a href={instagram} target="_blank" rel="noreferrer">Instagram</a></Typography>
              )
            }
            {
              youtube && (
                <Typography><a href={youtube} target="_blank" rel="noreferrer">Youtube</a></Typography>
              )
            }
          </Grid>
          <Grid item md={12} xs={12}>
            <Typography sx={{marginBottom: '20px'}}>
              @ {new Date().getFullYear()} wszelkie prawa zastrzeżone przez {company}. Strona stworzona
              przez &nbsp;
              <a
                href="https://your-site.pl"
                target="_blank"
                rel="noreferrer"
              >
                Your site
              </a>.
              Wszelkie informacje dostępne pod:
              <a
                href="#"
                target="_blank"
                rel="noreferrer"
              >
                {t?.homePage?.footer.desc2}
              </a>
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Box>
  )
}

export default Footer
