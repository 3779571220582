import BreakpointsHelper from '../../helpers/BreakpointsHelper/BreakpointsHelper'
import './DashboardPage.scss'
import Typography from '@mui/material/Typography'
import {useEffect, useState} from 'react'
import {Grid, useTheme} from '@mui/material'
import {useSnackbar} from 'notistack'
import Competition from '../../components/atoms/Competition/Competition'
import {getAllDataFromPath} from '../../firebase'
import DateHelper from '../../helpers/DateHelper/DateHelper'
import Seo from '../../components/molecules/Seo/Seo'

const DashboardPage = (props) => {
  const theme = useTheme()
  const [tournaments, setTournaments] = useState(null)
  const seo = theme.config.seo
  const notificationType = props?.location?.location?.search?.replace('?', '').split('=')[0]
  const style = {
    root: {
      width: BreakpointsHelper.isMobile() ? 'calc(100% - 30px)' : 'calc(100% - 110px)',
      padding: BreakpointsHelper.isMobile() ? '15px' : '20px 50px'
    },
    arrayNotExist: {
      fontSize: '16px',
      marginTop: '20px',
      color: '#324b54',
      background: '#ededed',
      padding: '20px',
      width: 'auto',
      marginLeft: '20px',
      borderRadius: '50px',
    }
  }
  const {enqueueSnackbar} = useSnackbar()
  useEffect(() => {
    if (notificationType) {
      switch (notificationType) {
        case 'deleteImageFromGallery':
          enqueueSnackbar('Poprawnie usunięto zdjęcie z galerii.', {variant: 'success'})
          break
      }
    }
  }, [notificationType])
  useEffect(() => {
    getAllDataFromPath('tournaments').then((res) => {
      if (res) {
        res?.sort((el, next) => {
          const dateEl = el?.data?.date?.split('-')
          const dateNext = next?.data?.date?.split('-')
          const elDate = new Date(dateEl[0], dateEl[1], dateEl[2])
          const nextDate = new Date(dateNext[0], dateNext[1], dateNext[2])
          return elDate - nextDate
        })
        setTournaments(res)
      }
    })
  }, [])
  return (
    <>
      <Seo data={{ 'seo: title': 'Fotograf Gajdamowicz - Panel administracyjny' }}/>
      <div style={style.root}>
        <Grid container spacing={4}>
          <Grid item md={12} xs={12}>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '30px',
                marginBottom: '10px'
              }}
            >
              Lista zaplanowanych sesji
            </Typography>
            <Typography
              sx={{
                fontWeight: '400',
                fontSize: '20px',
                marginBottom: '10px'
              }}
            >
              Zaplanowane sesje oraz te, które miały już miejsce. Po zakończeniu każdej sesji, fotograf umieszcza
              zdjęcia w dedykowanej przestrzeni, dzięki czemu każdy klient może w przyszłości bezproblemowo odnaleźć i
              przeglądać swoje fotografie. W widoku prezentowane są wyłącznie sesje dostępne dla zalogowanego
              użytkownika, gwarantując indywidualny dostęp i prywatność każdego z klientów.
            </Typography>
          </Grid>
          {
            tournaments?.lenngth > 0 ? tournaments?.map((el, index) => {
              const data = el?.data
              const dayToEvent = DateHelper.getDaysToEvent(el)
              if (dayToEvent >= 0) {
                return (
                  <Competition
                    key={index}
                    name={data?.name}
                    dates={data?.date}
                    region={data?.type}
                    city={data?.localization}
                  />
                )
              }
              return ''
            }) : (
              <Typography sx={style.arrayNotExist}>Nie ma dostępnych sesji.</Typography>
            )
          }
        </Grid>
      </div>
    </>
  )
}

export default DashboardPage
