import {Button, Grid} from '@mui/material'
import Box from '@mui/material/Box'
import sliderImage from '../../../assets/images/slider.png'
import Typography from '@mui/material/Typography'

const Slider = ({ home = false, title }) => {
  const style = {
    root: {
      display: 'flex',
      alignItems: 'center',
      padding: home ? '150px 0' : '100px 0 50px 0'
    },
    image: {
      width: '100%'
    },
    h1: {
      fontSize: '38px',
      fontWeight: '800'
    },
    h2: {
      fontSize: '18px',
      fontWeight: '400',
      marginTop: '20px'
    },
  }
  return (
    <Box sx={style.root}>
      <Grid container spacing={16}>
        <Grid item md={6} xs={12}>
          <Typography variant="h1" sx={style.h1}>
            {title || 'Fotograf Gajdamowicz'}
          </Typography>
          <Typography variant="h2" sx={style.h2}>
            Jako doświadczony fotograf z Koszalina świadczący usługi fotograficzne przy ulicy Zwycięstwa, moim celem
            jest uchwycenie najpiękniejszych i najważniejszych chwil w życiu moich klientów. Z pasją i zaangażowaniem
            podchodzę do robienia zdjęć, tworząc niezapomniane kadry i wspomnienia, które pozostaną z Wami na zawsze.
          </Typography>
          <Box sx={{ display: 'flex', marginTop: '20px' }}>
            <Button
              sx={{ marginRight: '20px' }}
              onClick={() => window.open('tel:+48723421062', '_self')}
              variant="outlined"
            >
              Zadzwoń
            </Button>
            <Button
              onClick={() => window.open('https://www.google.com/maps/place/Fotograf+Gajdamowicz+-+Zdj%C4%99cia+do+dokument%C3%B3w+Koszalin/@54.1900643,16.1803567,15z/data=!4m6!3m5!1s0x47004bf39bd0aa45:0xd68f25c3557499cb!8m2!3d54.1900643!4d16.1803567!16s%2Fg%2F11k67p60dx?coh=164777&entry=tt&shorturl=1', '_blank')}
              variant="contained"
            >
              Wyznacz trasę
            </Button>
          </Box>
        </Grid>
        <Grid item md={6} xs={12}>
          <img src={sliderImage} alt="slider image" style={style.image}/>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Slider
